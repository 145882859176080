
.WatermarkContainer
{
    display: flex;
    flex-direction: column;
    align-items: left;
    position: fixed;
    top: 90%;
    right: 10%;
    z-index: 100000;
}

.WMH2
{
    font-size: .75vmax;
    margin-top: 0vh;
    color: white;
}